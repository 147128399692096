import jQuery from "jquery";
import "bootstrap";

import Header from "./components/Header";

import { initSmoothScroll } from "./components/SmoothScroll.js";

const header = document.querySelector(".site-header");
if (header) {
  new Header(header);
}

document.addEventListener("DOMContentLoaded", () => {
  initSmoothScroll();
});
