class Header {
  constructor(header) {
    this.header = header;
    this.toggle = header.querySelector(".site-header__toggle");
    this.mobileMenu = header.querySelector(".site-header__mobile");
    this.mobileMenuOpen = false;
    this.events();
  }

  events() {
    this.toggle.addEventListener("click", (e) => this.toggleMobileMenu(e));
    window.addEventListener("scroll", () => this.handleScroll());

    for (let menuItemsWithSubMenu of this.mobileMenu.querySelectorAll(
      ".menu-item-has-children > a"
    )) {
      console.log(menuItemsWithSubMenu);
      menuItemsWithSubMenu.classList.add("testing");
      menuItemsWithSubMenu.addEventListener("click", (e) => {
        this.toggleMobileSubMenu(e.target);
        return false;
      });
    }
  }

  toggleMobileMenu(e) {
    this.mobileMenu.classList.toggle(
      "site-header__mobile--open",
      !this.mobileMenuOpen
    );
    this.toggle.classList.toggle(
      "site-header__toggle--open",
      !this.mobileMenuOpen
    );
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  toggleMobileSubMenu(el) {
    el.closest(".menu-item-has-children").classList.toggle("open");
  }

  handleScroll() {
    if (window.scrollY > 0) {
      this.header.classList.add("site-header--scrolled");
    } else {
      this.header.classList.remove("site-header--scrolled");
    }
  }
}

export default Header;
